<template>
    <div class="py-4 container-fluid" >
        <div class="row">
           <div class="col-12">
              <div class="card mb-4">
                 <div class="card-header pb-0">
                    <div class="d-flex flex-row mb-0 align-items-center">
                        <div class="p-2">
                            <h6>{{ tableTitle }}</h6>
                        </div>
                        <div class="p-2 ms-auto">
                            <button type="button" class="btn btn-outline-success rounded-pill btn-sm" data-bs-toggle="modal" data-bs-target="#add-frequency">Add</button>
                        </div>
                    </div>
                 </div>
                 <div v-if="isLoading" class="d-flex justify-content-center p-5">
                    <div class="spinner-border text-info" role="status">
                    </div>
                 </div>
                 <div class="card-body px-0 pt-0 pb-2 mx-2" v-show="!isLoading">
                    <div class="table-responsive p-0">
                       <dashboard-table 
                       :tableStripped="true"
                       :columnHeadings="tableHeadings"
                       :data="tableData"
                       :identityPropertyName="identityColumn"
                       :visibleColumns="tableVisibleColumns"
                       :showActionButton="false"
                       :showEditButton="false"
                       :showDeleteButton="false"
                       :tableColumnProps="tableColumnProps"
                       :hasPagination="true"
                       :selectedPage="selectedPage"
                       :totalPages="totalPages"
                       :pageSize="pageSize"
                       @edit-row="editRow"
                       @delete-row="deleteRow"
                       @view-row="viewRow"
                       @change-page="changePage">
                       <template v-slot:buttons>
                         <!-- <td class="align-middle ps-4" :id="identityValue"><a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">Delete</a></td> -->
                       </template>
                       </dashboard-table>
                    </div>
                    <button-large class="mt-5" icon="fa fa-plus" data-bs-toggle="modal" data-bs-target="#add-category"/>
                 </div>
              </div>
           </div>
        </div>
     </div>
</template>

<script>
import DashboardTable from '../../components/DataTable.vue';
import ServiceService from "../../services/ServiceService";

export default {
  name: "services",
  setup(){
    const isLoading = true;
    return{
        isLoading
    } 
  },
  components: {
    DashboardTable,
  },
  async mounted() {
    var response = await ServiceService.getPagination({pageNumber: this.pageNumber, pageSize: this.pageSize});
      this.tableData = response.items;
      this.pageNumber = response.pageNumber;
      this.totalPages = response.totalPages;
      console.log(this.totalPages)
      this.totalCount = response.totalCount;
      this.isLoading = false;
  },
  data(){
    return{
      tableTitle: 'Services',
      tableHeadings: [{ name:'Name'},{ name:'Code'},{ name:'Type'},{name:"Stripe Product Id"}],
      tableColumnProps: [
        {name:'serviceId', type:'number'},
        {name:'serviceName', type:'string'},
        {name:'serviceCode', type:'string'},
        {name:'type', type:'enum',  enumData: [
          {number:1,name:'Hourly'},{number:2,name:'Property'},
        ]},
        {name:'stripeProductId', type:'string'},
      ],
      identityColumn:'serviceId',
      tableVisibleColumns:['serviceName','serviceCode','type','stripeProductId'],
      tableData: [],
      pageSize: 10,
      selectedPage: 1,
      pageNumber: 1,
      totalPages: 1,
      totalCount:1,
    }
  },
  async created() {
      
   // this.$store.dispatch("breadcrumb/reset");
  },
  methods:{
    editRow(identityValue) {
      console.log("edit"+ identityValue);
    },
    deleteRow(identityValue) {
      this.frequencies = this.frequencies.filter(item => item.frequencyId != identityValue);
      console.log("delete"+ identityValue);
    },
    viewRow(identityValue) {
      console.log("view"+ identityValue);
    },
    async changePage(pageNumber) {
      // console.log(pageNumber);
        this.selectedPage = pageNumber;
        this.isLoading = true;
        var response = await ServiceService.getPagination({pageNumber: pageNumber, pageSize: this.pageSize});
        this.tableData = response.items;
        this.pageNumber = response.pageNumber;
        this.totalPages = response.totalPages;
        this.totalCount = response.totalCount;
        this.isLoading = false;
        //this.updateTotalPages(10);
    },
    async updateTotalPages(count) {
      this.pageNumber = count;
    },
  },
};
</script>