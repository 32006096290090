<template>
    <div class="card my-5 mx-10">
        <div class="card-header text-center">
            <div class="row justify-content-between">
                <div class="col-md-4 text-start">
                    <h6>
                        Send Email
                    </h6>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <form>
                        <div class="form-group">
                            <label for="exampleFormControlInput1">To</label>
                            <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" v-model="data.to">
                        </div>
                        <div class="form-group">
                            <label for="example-text-input" class="form-control-label">Subject</label>
                            <input class="form-control" type="text" id="example-text-input" v-model="data.subject">
                        </div>
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1">Message</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="data.message"></textarea>
                        </div>
                        <div class="form-group">
                            <label for="example-text-input" class="form-control-label">Sender Name</label>
                            <input class="form-control" type="text" id="example-text-input" v-model="data.senderName">
                        </div>
                        <div class="form-group">
                            <label for="example-text-input" class="form-control-label">Sender Title</label>
                            <input class="form-control" type="text" id="example-text-input" v-model="data.senderTitle">
                        </div>
                        <div class="form-group">
                            <label for="example-text-input" class="form-control-label">Sender Phone Number</label>
                            <input class="form-control" type="text" id="example-text-input" v-model="data.senderPhoneNumber">
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-lg-12 text-md-end mt-md-0">
                    <div v-if="isSending" class="d-flex justify-content-center p-5">
                        <div class="spinner-border text-info" role="status">
                        </div>
                    </div>
                    <button class="btn bg-gradient-info mb-0" @click="sendMail" type="button" name="button" v-else>Send</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EmailService from "../../services/EmailService";
export default {
    name: "emails",
    data(){
        return{
            isSending: false,
            data:{
                to:'',
                message:'',
                subject:'',
                senderName:'',
                senderTitle:'',
                senderPhoneNumber:''
            }
        }
    },
    methods:{
        async sendMail(){
            this.isSending = true;
            let isError = false;
            var result = await EmailService.sendPersonalEmail(this.data).catch((error) => {
                isError = true;
                let message = result.message ? result.message : 'Unable to send email. Please provide valid information.';
                this.$toast.error(message, {
                    position: 'top-right',
                });
            });
            this.isSending = false;
            if(!isError){
                if(result.success){
                    this.$toast.success(result.message, {
                        position: "top-right",
                    });
                    this.data.to = '';
                    this.data.subject = '';
                    this.data.message = '';
                } else {
                    this.$toast.error(result.message, {
                        position: "top-right",
                    });
                }
                
            }
        }
    }
}
</script>

