<template>
    <div class="py-4 container">
        <div class="flex-row justify-content-center">
            <div class="col-12">
                 <h1>OOPS...! Page Not Found :(</h1>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "error-not-found",
};
</script>