<template>
                        <table class="table align-items-center mb-0 " :class="{'table-striped ': tableStripped}">
                          <thead>
                             <tr>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">No.</th>
                               <slot name="head"></slot>
                             </tr>
                          </thead>
                          <tbody>
                              <slot name="body"></slot>
                          </tbody>
                       </table>
                        <slot name="pagination"></slot>
</template>
<script>
export default {
    name: "d-table",
    props: {
        tableStripped: Boolean,
    },
}
</script>

<style>

</style>