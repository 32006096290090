<template>
  <nav aria-label="breadcrumb">
    <ol
      class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb"
      :class="this.$store.state.isRTL ? '' : ' me-sm-6'"
      v-if="validateList"
    >
      <!-- <li class="text-sm breadcrumb-item" :class="textWhite">
        <a
          v-if="this.$store.state.isRTL"
          :class="textWhite"
          class="opacity-5 ps-2"
          href="#"
          >Dashboard</a
        >
        <a v-else :class="textWhite" class="opacity-8" href="#">Dashboard</a>
      </li> -->
      <!-- <li
        class="text-sm breadcrumb-item active"
        :class="textWhite ? 'text-white' : 'text-dark'"
        aria-current="page"
      >
        {{ currentPage }}
      </li> -->
      <li class="text-sm breadcrumb-item" :class="getClass(item)" v-for='(item, key) in this.$store.state.breadcrumb' :key='key'>
        <a
          v-if="this.$store.state.isRTL"
          :class="textWhite"
          class="opacity-5 ps-2"
          href="#"
          >{{ item }}</a
        >
        <a v-else :class="textWhite" class="opacity-8" :href="item.link">{{ item.name }}</a>
      </li>
    </ol>
    <h6 class="mb-0 font-weight-bolder" :class="textWhite ? 'text-white' : ''">
      <span><i v-if="hasPreviousPath && currentPage != 'dashboard'" class="fa fa-angle-left fa-2x" aria-hidden="true" @click="goBack()"></i>  {{ capitalizeFirstLetter(currentPage) }}</span>
    </h6>
  </nav>
</template>

<script>
export default {
  name: "breadcrumbs",
  props: {
    currentPage: {
      required: true,
    },
    currentPath: {
      required: true,
    },
    textWhite: {
      type: String,
    },
  },
  computed: {
    getPathList(){
      let pathList = this.currentPath.split("/");
      console.log(pathList)
      return pathList;
    },
    getBreadcrumbs(){
      return this.$store.state.breadcrumb;
    },
    validateList(){
      return typeof(this.$store.state.breadcrumb) !== 'undefined'
    },
    hasPreviousPath(){
      let path = this.$router.options.history.state.back;
      console.log(path);
      return path ? true : false;
    },
  },
  data() {
    return {
      pathList: [],
    };
  },
  methods: {
    capitalizeFirstLetter(text){
      //return text.substr(0,1).toUpperCase() + text.substr(1);
      return text;
    },
    getClass(item){
      //console.log('get breadcrumb');console.log(item);
      let value = this.textWhite;
      if(typeof(item) !== "undefined") {
        value = item.active ?' active':'';
      }
      return value;
    },
    goBack(){
      let path = this.$router.options.history.state.back;
      this.$router.push(path);
    },
  },
  // created() {
  //   this.$store.dispatch("breadcrumb/reset");
  // },
};
</script>
