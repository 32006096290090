<template>
    <div class="py-4 container-fluid" >
        <div class="row">
           <div class="col-12">
              <div class="card mb-4">
                 <div class="card-header pb-0">
                    <div class="d-flex flex-row mb-0 align-items-center">
                        <div class="p-2">
                            <h6>{{ tableTitle }}</h6>
                        </div>
                    </div>
                 </div>
                 <div v-if="isLoading" class="d-flex justify-content-center p-5">
                    <div class="spinner-border text-info" role="status">
                    </div>
                 </div>
                 <div class="card-body px-0 pt-0 pb-2 mx-2" v-show="!isLoading">
                 <div class="d-flex justify-content-between align-items-center px-5 py-2 mx-3" :class="{ 'bg-secondary': index % 2 == 0 }" v-for='(item,index) in settings' :key='index' >
                            <h6 >{{ item.description }}</h6> 
                            <div class="form-group" v-if='item.value'>
                                <div autocomplete="off" class="input-group input-group-alternative">
                                    <input class="form-control" placeholder="" type="text" @focus="onValueFocus('value-'+item.settingId.toString())" @focusout="changeValue(item.settingId,item.value)" :ref="'value-'+item.settingId.toString()" v-model="item.value" disabled>
                                    <span class="input-group-text"><i class="fa fa-edit" @click="enableValueField('value-'+item.settingId.toString())"></i></span>
                                </div>
                            </div>
                            <div class="form-check form-switch" v-else>
                                <input class="form-check-input" type="checkbox" :id="'switch'+item.settingId.toString()" :checked="item.active" @change="changed(index, item.active)">
                                <label class="form-check-label" :for="'switch'+item.settingId.toString()"></label>
                            </div>
                            <!-- <soft-switch :id="item.settingId.toString()" :checked="item.active" @changed="item.active = !item.active" v-else></soft-switch> -->
                        </div>
                 </div>
              </div>
           </div>
        </div>
        <div class="row">
           <div class="col-12">
              <div class="card mb-4">
                 <div class="card-header pb-0">
                    <div class="d-flex flex-row mb-0 align-items-center">
                        <div class="p-2">
                            <h6> Secrets </h6>
                        </div>
                    </div>
                 </div>
                 <div v-if="isLoading" class="d-flex justify-content-center p-5">
                    <div class="spinner-border text-info" role="status">
                    </div>
                 </div>
                 <div class="card-body px-0 pt-0 pb-2 mx-2 mb-3" v-show="!isLoading">
                 <div class="d-flex justify-content-between align-items-center px-5 py-2 mx-3" :class="{ 'bg-secondary': index % 2 == 0 }" v-for='(item,index) in configs' :key='index' >
                            <h6 >{{ item.description }}</h6>
                            <div class="form-group" v-if='item.value'>
                                <div autocomplete="off" class="input-group input-group-alternative">
                                    <input class="form-control" placeholder="********" type="password" @focus="onValueFocus('value-'+item.settingId.toString())" @focusout="changeValue(item.settingId,item.value)" :ref="'value-'+item.settingId.toString()" v-model="item.value" disabled>
                                    <span class="input-group-text"><i class="fa fa-edit" @click="enableValueField('value-'+item.settingId.toString())"></i></span>
                                </div>
                            </div>
                            <div class="form-check form-switch" v-else>
                                <input class="form-check-input" autocomplete="off" type="checkbox" :id="'switch'+item.settingId.toString()" :checked="item.active" @change="changed(index, item.active)">
                                <label class="form-check-label" :for="'switch'+item.settingId.toString()"></label>
                            </div>
                        </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
</template>

<script>
import SettingService from "../../services/SettingService";
export default {
    name: "settings",
    data() {
        return {
            tableTitle: "Settings",
            tableHeadings: [{ name: "Description" }, { name: "Secrete" }],
            tableColumnProps: [
                { name: "feedbackId", type: "number" },
                { name: "name", type: "string" },
                { name: "sentTime", type: "date" },
                { name: "message", type: "string" },
                { name: "subject", type: "string" },
                { name: "email", type: "string" },
            ],
            identityColumn: "settingId",
            tableVisibleColumns: ["name", "subject", "message", "email", "sentTime"],
            settings: [],
            configs: [],
            pageSize: 10,
            selectedPage: 1,
            pageNumber: 1,
            totalPages: 1,
            totalCount: 1,
            isLoading: true,
        };
    },
    async created() {
        var response = await SettingService.getAll();
        this.settings = response;
        var congigResponse = await SettingService.getAllConfigs();
        this.configs = congigResponse;
        this.isLoading = false;
    },
    methods: {
        editRow(identityValue) {
            console.log("edit" + identityValue);
        },
        deleteRow(identityValue) {
            this.frequencies = this.frequencies.filter(item => item.frequencyId != identityValue);
            console.log("delete" + identityValue);
        },
        viewRow(identityValue) {
            console.log("view" + identityValue);
        },
        showPopover(index){
            console.log('showPopover');
        },
        async changed(index, value){
            console.log(index);
            console.log(value);
            let setting = this.settings[index];
            let id = setting.settingId;
            
            var response = await SettingService.updateStatus(id).catch((error) => {
                alert("Unable to update setting. Please try again.");
                // console.log(setting);
                // setting.active = value;
                // this.settings[index] = setting;
                this.refreshPage();
            });
        },
        async changeValue(id, value){
            if(value !== null) {
                var response = await SettingService.updateValue(id, { value: value });
                var input = this.$refs['value-'+id]
                input.disabled = false;
                if(!response){
                    alert("Unable to update");
                }
            } 
            
        },
        async refreshPage(){
            this.isLoading = true;
            var response = await SettingService.getAll();
            this.settings = response;
            var congigResponse = await SettingService.getAllConfigs();
            this.configs = congigResponse;
            this.isLoading = false;
        },
        async onValueFocus(id){
            var input = this.$refs[id.toString()]
            console.log(input);
        },
        async enableValueField(id){
            var input = this.$refs[id.toString()]
            input.disabled = false;
        },
    },
};
</script>