import GlobalComponents from "./plugins/globalComponents";
import "./assets/js/nav-pills.js";
import "./assets/scss/soft-ui-dashboard.scss";
import "bootstrap/dist/js/bootstrap";



export default {
  install(app) {
    app.use(GlobalComponents);
  },
};
