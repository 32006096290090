const defaultData = {name:'Dashboard', link:'/dashboard', active:false};
const initialState = [];

export const breadcrumb =  {
    namespaced: true,
    state: initialState,
    actions: {
      add({ commit }, item) {
        commit("addBreadcrumb", item);
        Promise.resolve(item);
      },
      removeLast({ commit }) {
        commit("removeBreadcrumb");
        Promise.resolve();
      },
      reset({ commit }) {
        commit("resetBreadcrumb");
        Promise.resolve();
      },
      clear({ commit }) {
        commit("clearBreadcrumb");
        Promise.resolve();
      },
    },
    mutations: {
        addBreadcrumb(state, item) {
          if(item !== null && typeof(item) !== "undefined") {
            state.push(item);
          }
        },
        removeBreadcrumb(state) {
          state.slice(-1);
        },
        resetBreadcrumb(state) {
          let length = state.length;
          for (let i = 0; i < length; i++) {
            state.pop();
          }
        },
        clearBreadcrumb(state) {
          state.clear();
        },
      },
};