<template>
    <vsud-pagination color="success" class="d-flex justify-content-end mt-3">
        <vsud-pagination-item first @click="firstPage" v-if="hasPreviousPage && selectedPage > 3"/>
        <vsud-pagination-item prev v-if="hasPreviousPage" @click="previousPage"/>
        <vsud-pagination-item :label="page" :active="selectedPage === page"  v-for='page in getPages' :key='page'  @click="onChangePage(page)"/>
        <vsud-pagination-item next  v-if="hasNextPage" @click="nextPage"/>
        <vsud-pagination-item last  v-if="hasNextPage" @click="lastPage"/>
    </vsud-pagination>
</template>

<script>
import VsudPagination from "@/components/VsudPagination.vue";
import VsudPaginationItem from "@/components/VsudPaginationItem.vue";
export default {
    name:'d-table-pagination',
    components: {
        VsudPagination,
        VsudPaginationItem,
   },
    emits: ["changePage"],
    props: {
        selectedPage:{
            type: Number,
            required: false,
            default: 1,
        },
        totalPages:{
            type: Number,
            required: false,
            default: 10,
        },
        pageSize: {
            type: Number,
            required: false,
            default: 10,
        }
    },
    computed: {
        getPages(){
            if(this.totalPages > 5){
                var startFrom =  this.selectedPage - 2;
                startFrom = startFrom < 1 ? 1 : startFrom;
                startFrom = (startFrom + 5) > this.totalPages ? this.totalPages -4 : startFrom;
                var pages = Array.from({length: 5}, (_, i) => i + startFrom);
                return pages;
            } else {
                return Array.from({length: this.totalPages}, (_, i) => i + 1)
            }
        },
      hasNextPage: function () {
        return this.totalPages < 5 ? false : this.selectedPage == this.totalPages ? false : !this.getPages.includes(this.totalPages);
      },
      hasPreviousPage: function () {
        return this.selectedPage < 2 ? false : this.totalPages < 5 ? false : true
      },
    },
    methods: {
        onChangePage(index) {
            //var pageNumber = this.getPageNumber(index);
            if(index !== this.selectedPage) {
                this.$emit("change-page", index);
            }
        },
        getPageNumber(index){
            return index + this.selectedPage;
        },
        nextPage(){
            this.onChangePage(this.selectedPage+1)
        },
        previousPage(){
            this.onChangePage(this.selectedPage-1)
        },
        firstPage(){
            this.onChangePage(1)
        },
        lastPage(){
            this.onChangePage(this.totalPages)
        },
    },
}
</script>

<style>

</style>