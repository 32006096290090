<template>
   <div class="p-2 " :class="{'bg-success-light': isHovered}" @mouseover="isHovered=true" @mouseleave="isHovered=false">
      <div class="d-flex align-items-center" >
         <div class="p-2 bd-highlight">
            <img src="..\..\src\assets\img\sample\product.png" class="rounded float-start card-list-img img-thumbnail" alt="...">
         </div>
         <div class="p-2 bd-highlight">
            <div> {{ name }} </div>
            <div v-if="hasSubList"> {{ noOfSubItems }} {{ subListName }} {{ itemIndex }}</div>
         </div>
         <div class="ms-auto p-2 bd-highlight" :class="getHoverColor" >
             <slot name="right-buttons" v-bind:hovered="isHovered" v-bind:itemIndex="itemIndex">
             </slot>
         </div>
      </div>
   </div>
</template>
<script>
//import ButtonCircle from './ButtonCircle.vue';

    export default {
      name: "card-list-item",
      components: { 
         //ButtonCircle 
         },
      props: {
         showDivider: Boolean,
         itemIndex: Number,
         name: String,
         noOfSubItems: Number,
         subListName: String,
         hasSubList : Boolean,
      },
      data() {
          return {
            isHovered : false,
          }
      },
      computed:{
         getHoverColor(){
            return this.isHovered ? 'bg-success-light' : '';
         },
      },
    };
</script>