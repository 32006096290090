import axios from "axios";
import Base_API_URL from "../constants/base.js";
import http from "../common/http-config";
//import http from '../common/http-config'

const API_URL = Base_API_URL.API_URL + "Authentications/";

class AuthService {
  async login(user) {
    return await axios
      .post(API_URL + "Login", {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        const data = response.data
        if (data.success) {
          console.log("login token")
          const userData = data.data
          //localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem("token", userData.token);
          let user = {name: userData.user};
          localStorage.setItem("user", JSON.stringify(user));
          //localStorage.setItem("user", user);
          localStorage.setItem("refreshToken", userData.refreshToken);
          localStorage.setItem("permissions", userData.permissions);
          console.log("login success")
        } else {
          console.log("login not success")
        }
        return response.data;
      });
  }

  async refreshToken() {
    return await axios
      .post(API_URL + "RefreshToken", {
        token: localStorage.getItem("refreshToken")
      })
      .then((response) => {
        console.log(response);
        console.log("refreshtoken");
        if (response.data.token) {
          //localStorage.setItem("user", JSON.stringify(response.data));
          const userData = response.data
          localStorage.setItem("token", userData.token);
          localStorage.setItem("refreshToken", userData.refreshToken);
          localStorage.setItem("permissions", userData.permissions);
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("permissions");
  }

  register(user) {
    return axios.post(API_URL + "signup", {
      username: user.username,
      email: user.email,
      password: user.password,
    });
  }
}
export default new AuthService();