import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueToast from 'vue-toast-notification';
//import VueRouterBackButton from "vue-router-back-button";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/css/app.css";
import 'vue-toast-notification/dist/theme-sugar.css';
import SoftUIDashboard from "./soft-ui-dashboard";
// import "./services/toasts"

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
//appInstance.use(VueRouterBackButton, { router });
appInstance.use(SoftUIDashboard);
appInstance.use(VueToast);
appInstance.use(VueAxios, axios)
appInstance.mount("#app");
