import http from "../common/http-config";

const apiPrefix = `Services/`;

class ServiceService {
    async getAll() {
        return await http.get(apiPrefix).then((response) => {
          if (response.data) {
            return response.data.data;
          } else {
            return [];
          }
        });
    }
    async getPagination(model) {
        return await http.post(apiPrefix+"Paginated", model).then((response) => {
          if (response.data) {
            return response.data.data;
          } else {
            return [];
          }
        });
    }
  async getById(id) {
      return await http.get(apiPrefix+id).then((response) => {
        if (response.data) {
          return response.data;
        } else {
          return [];
        }
      });
  }
  async add(modle) {
    //let category = {name: name, description: description, visible: visible}
    return await http.post(apiPrefix, modle).then((response) => {
      return response.data;
    });
  }
  async archive(id) {
    return await http.get(apiPrefix+id+"/Archive").then((response) => {
      return response.data;
    });
  }
  async restore(id) {
    return await http.get(apiPrefix+id+"/Restore").then((response) => {
      return response.data;
    });
  }
  async delete(id) {
    return await http.delete(apiPrefix+id).then((response) => {
      return response.data;
    });
  }
}
export default new ServiceService()