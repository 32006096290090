import CardList from "../components/CardList.vue";
import AddModel from "../components/AddModel.vue";
import ButtonCircle from '../components/ButtonCircle.vue';
import ButttonLarge from '../components/ButttonLarge.vue';
import DTable from '../components/table/DTable.vue';
import DTableColumn from '../components/table/DTableColumn.vue';
import DTableHeading from '../components/table/DTableHeading.vue';
import DTableRow from '../components/table/DTableRow.vue';
import DTablePagination from '../components/table/DTablePagination.vue';
import DataTime from '../components/common/DataTime.vue';

const GlobalComponents = {
    install(app) {
      app.component("card-list", CardList);
      app.component("add-model", AddModel);
      app.component("button-circle", ButtonCircle);
      app.component("button-large", ButttonLarge);
      app.component("d-table", DTable);
      app.component("d-table-column", DTableColumn);
      app.component("d-table-h", DTableHeading);
      app.component("d-table-row", DTableRow);
      app.component("d-table-pagination", DTablePagination);
      app.component("data-time", DataTime);
    },
  };
  
  export default GlobalComponents;