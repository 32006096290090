<template>
    <td class="align-middle ps-4 text-truncate max" @click="$emit('column-clicked')" @mouseover="$emit('column-hovered')" >
           <slot></slot>
    </td>
</template>

<script>
export default {
    name:'d-tc',
    emits:['column-clicked','column-hovered'],
}
</script>

<style>

</style>