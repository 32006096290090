<template>
    <div class="d-flex justify-content-center rounded bg-outline-success border-success mx-3 py-4 mb-3 border-dotted">
                                  <span class="btn-outline-success">
                                     <i :class='icon' aria-hidden="true"></i>
                                  </span>
                               </div>
</template>

<script>
    export default {
      name: "button-large",
      props: {
         icon: String,
      },
    };
</script>