<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header pb-0">
            <div class="d-flex flex-row mb-0 align-items-center">
              <div class="p-2">
                <h6>{{ tableTitle }}</h6>
              </div>
            </div>
          </div>
          <div v-if="isLoading" class="d-flex justify-content-center p-5">
            <div class="spinner-border text-info" role="status">
            </div>
          </div>
          <div class="card-body px-0 pt-0 pb-2 mx-2" v-else>
            <div clas="row">
              <div class="col-12 col-md-6 col-lg-6">
                <br>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <div class="form-group">
                  <div class="input-group input-group-alternative mb-4">
                    <input class="form-control" placeholder="Search" type="text" v-model="search">
                    <span class="input-group-text"><i class="ni ni-zoom-split-in" @click="searchBooking"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <d-table :tableStripped="true">
              <template v-slot:head>
                <d-table-h v-for='(heading,index) in tableHeadings' :key='index'>
                  {{ heading.name }}
                </d-table-h>
              </template>
              <template v-slot:body>
                <d-table-row :number="index+1" v-for='(item,index) in tableData' :key='index'>
                  <template v-slot:columns>
                    <d-table-column @column-clicked="null">
                      <h6>{{ item.bookingNumber }}</h6>
                    </d-table-column>
                    <d-table-column @column-clicked="null">
                      <span class="badge" :class="getStatusClass(item.status)">{{ getStatus(item.status) }}</span>
                    </d-table-column>
                    <d-table-column @column-clicked="null">
                     <span class="badge" :class="getPaymentStatusClass(item.lastPaymentStatus)">{{ getPaymentStatus(item.lastPaymentStatus) }}</span>
                    </d-table-column>
                    <d-table-column @column-clicked="null">
                      <data-time :date="item.bookedDate" :fromNow="false" :showTime="false">
                      </data-time>
                    </d-table-column>
                    <d-table-column @column-clicked="null">
                      <data-time :date="item.startDate" :fromNow="false" :showTime="false">
                      </data-time>
                    </d-table-column>
                    <d-table-column @column-clicked="null">
                      <h6>{{ item.customer.firstName + ' ' + item.customer.lastName  }}</h6>
                    </d-table-column>
                    <d-table-column @column-clicked="null">
                      <h6>{{ item.customer.phoneNumber }}</h6>
                    </d-table-column>
                    <d-table-column @column-clicked="null">
                      <button class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="View row" @click="viewInvoice(item.bookingNumber)">View</button>
                    </d-table-column>
                  </template>
                </d-table-row>
              </template>
              <template v-slot:pagination>
                <d-table-pagination :pageSize="pageSize" :totalPages="totalPages" :selectedPage="selectedPage"
                  @change-page="changePage"></d-table-pagination>
              </template>
            </d-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BookingService from "../../services/BookingService";
import DataTime from "../../components/common/DataTime.vue";
export default {
  name: "bookings",
  components: { DataTime },
  data(){
    return{
      tableTitle: 'Bookings',
      tableHeadings: [{ name:'Booking Number'},{ name:'Status'},{ name:'Payment'},{ name:'Booked Date'},{name:'Service Date'},{name:'Customer Name'},{name:'Customer Number'}],
      statuses:[
          {number:0,name:'Pending'},{number:1,name:'New'},{number:2,name:'Accepted'},{number:3,name:'Completed'},{number:4,name:'Cancelled'},
      ],
      identityColumn:'bookingId',
      tableData: [],
      pageSize: 10,
      selectedPage: 1,
      pageNumber: 1,
      totalPages: 1,
      totalCount:1,
      search:'',
      isLoading: false,
    }
  },
  async created() {
      var response = await BookingService.getPagination({pageNumber: this.pageNumber, pageSize: this.pageSize});
      this.tableData = response.items;
        this.pageNumber = response.pageNumber;
        this.totalPages = response.totalPages;
        this.totalCount = response.totalCount;
        this.isLoading = false;
  },
  methods:{
    editRow(identityValue) {
      console.log("edit"+ identityValue);
    },
    deleteRow(identityValue) {
      this.frequencies = this.frequencies.filter(item => item.frequencyId != identityValue);
      console.log("delete"+ identityValue);
    },
    viewInvoice(bookingNumber) {
      console.log("inoice clicked");
      console.log(bookingNumber);
      this.$router.push({ name: 'Invoice', params: { bookingNumber: bookingNumber}});
    },
    async changePage(pageNumber) {
        this.selectedPage = pageNumber;
        this.isLoading = true;
        var response = await BookingService.getPagination({pageNumber: pageNumber, pageSize: this.pageSize, search: this.search});
        console.log(response);
        this.tableData = response.items;
        this.pageNumber = response.pageNumber;
        this.totalPages = response.totalPages;
        this.totalCount = response.totalCount;
        this.isLoading = false;
    },
    getStatus(number){
            var enumName = '';
            this.statuses.filter((element) => {
                if(element.number == number){
                    enumName = element.name;                
                }
            })
            return enumName;
        },
    async searchBooking() {
        this.isLoading = true;
        var response = await BookingService.getPagination({pageNumber: this.pageNumber , pageSize: this.pageSize, search: this.search});
        console.log(response);
        this.tableData = response.items;
        this.pageNumber = response.pageNumber;
        this.totalPages = response.totalPages;
        this.totalCount = response.totalCount;
        this.isLoading = false;
    },
    getPaymentStatus(number){
            var enumName = '';
            let statuses = [{number:1,name:'Pending'},{number:2,name:'Paid'},{number:3,name:'Failed'},{number:4,name:'Cancelled'}]
            statuses.filter((element) => {
                if(element.number == number){
                    enumName = element.name;                
                }
            })
            return enumName;
        },
        getPaymentStatusClass(status){
          if(status === 1){
            return 'bg-gradient-warning';
          }
          if(status === 2){
            return 'bg-gradient-success';
          }
          if(status === 3){
            return 'bg-gradient-danger';
          }
          if(status === 4){
            return 'bg-gradient-secondary';
          }
        },
        getStatusClass(status){
          if(status === 0){
            return 'bg-gradient-warning';
          }
          if(status === 1){
            return 'bg-gradient-info';
          }
          if(status === 2){
            return 'bg-gradient-primary';
          }
          if(status === 3){
            return 'bg-gradient-success';
          }
          if(status === 4){
            return 'bg-gradient-secondary';
          }
        }
  },
};
</script>