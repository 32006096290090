import { createRouter, createWebHistory } from "vue-router";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import Brands from "@/views/brands/Brands.vue";
import Frequencies from "@/views/frequencies/Frequencies.vue";
import Services from "@/views/services/Services.vue";
import Service from "@/views/services/Service.vue";
import Bookings from "@/views/bookings/Bookings.vue";
import Invoice from "@/views/bookings/Invoice.vue";
import Feedbacks from "@/views/feedbacks/Feedbacks.vue";
import Recruitments from "@/views/recruitments/Recruitments.vue";
import Settings from "@/views/settings/Settings.vue";
import Emails from "@/views/emails/Emails.vue";
import Error404 from "@/views/errors/error404.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/bookings",
  },
  {
    path: "/brands",
    name: "Brands",
    component: Brands,
  },
  {
    path: "/freciencies",
    name: "Frequencies",
    component: Frequencies,
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
  },
  {
    path: "/services/:serviceId",
    name: "Service",
    component: Service,
  },
  {
    path: "/emails",
    name: "Emails",
    component: Emails,
  },
  {
    path: "/bookings/:bookingNumber",
    name: "Invoice",
    props: true,
    component: Invoice,
  },
  {
    path: "/bookings",
    name: "Bookings",
    component: Bookings,
  },
  {
    path: "/feedbacks",
    name: "Feedbacks",
    component: Feedbacks,
  },
  {
    path: "/recruitments",
    name: "Recruitments",
    component: Recruitments,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
  {
    path: "/error-404",
    name: "Error 404",
    component: Error404,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

// router.beforeEach((to, from, next) => {
//   console.log(to) 
//   console.log(from) 
//   console.log(next)
//   console.log('before each')
//   if (from.path !== to.path || from.name !== to.name) {
//     console.log('step one')
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//       console.log('step two')
//       if (localStorage.getItem('token')) {
//         console.log('step tree')
//         next()
//       } else {
//         console.log("sign-in push to..")
//         next('/sign-in')
//       }
//     } else {
//       console.log('step four')
//       next()
//     }
//   }
// })

// const router = createRouter({
//   history: createWebHashHistory(),
//   linkActiveClass: "active",
//   routes,
// });
// eslint-disable-next-line no-undef
// Vue.use(Auth, {
//   issuer: "https://dev-914982.okta.com/oauth2/default",
//   client_id: "0oatq53f87ByM08MQ4x6",
//   redirect_uri: "https://localhost:5001/implicit/callback",
//   scope: "openid profile email",
// });
// router.beforeEach(Vue.prototype.$auth.authRedirectGuard());

router.beforeEach((to, _from, next) => {
  const publicPages = ["/sign-in", "/sign-up", "/home", "/about"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("token");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/sign-in");
  } else {
    next();
  }
});

export default router;
