<template>
<div class="modal fade" :id="idName" tabindex="-1" role="dialog" :aria-labelledby="idName" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h3 class="font-weight-bolder text-info text-gradient">{{ title }}</h3>
                <p class="mb-0"> {{ description }}</p>
              </div>
              <div class="card-body">
                <slot name="body-content">  </slot>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <slot name="body-footer"></slot>
            <button ref="Close" type="button" class="close btn btn-link  ml-auto" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";

export default {
    name: "add-model",
    components: {
        
    },
    // mounted() {
    //   this.modal = new bootstrap.Modal(document.getElementById(this.idName))
    // },
    props: {
      idName: String,
      title: String,
      description: String,
    },
};
</script>