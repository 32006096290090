<template>
    <tr :class="{'thead-dark': number % 2 == 0}">
        <d-table-column  @column-clicked="null">
            {{ number }}
        </d-table-column>
        <slot name="columns">
        </slot>
    </tr>
</template>
<script>
import DTableColumn from './DTableColumn.vue'
export default {
  components: { DTableColumn },
    name: "d-tr",
    props: {
        number: Number,
    },
}
</script>

<style>

</style>