<template>
<div @mouseover="null">
    {{ getDate }}
</div>
</template>

<script>
import { tryStatement } from '@babel/types';
import moment from 'moment'
export default {
    name: 'data-time',
    props: {
        date: String,
        fromNow: {
            type: Boolean,
            required: false,
            default: true,
        },
        showTime: {
            type: Boolean,
            required: false,
            default: true,
        }
    },
    computed: {
        getDate(){
            return this.fromNow ? moment.utc(this.date).fromNow() : this.getDate2;
        },
        getDate2(){
            var stillUtc = moment.utc(this.date).toDate();
            return this.showTime ? moment(stillUtc).local().format('DD-MM-YYYY HH:mm:ss') : moment(stillUtc).local().format('DD-MM-YYYY');
        }
    }
}
</script>

<style>

</style>