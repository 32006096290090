<template>
    <div class="card my-sm-5" id="invoiceContent">
        <div class="card-header text-center">
            <div class="row justify-content-between" v-if="invoice">
                <div class="col-md-4 text-start">
                    <img class="mb-2 w-25 p-2" src="../../assets/img/globus-logo.png" alt="Logo">
                    <h6>
                        Globus Cleaning Service LTD
                    </h6>
                    <p class="d-block text-secondary">tel: +44 7359 185 063</p>
                </div>
                <div class="col-lg-3 col-md-7 text-md-end text-start mt-5" v-if="invoice.customer">
                    <h6 class="d-block mt-2 mb-0">Billed to: {{ invoice.customer.firstName +' '+ invoice.customer.lastName }}</h6>
                    <p class="text-secondary">
                        <span v-if="invoice.address.streetAddress">{{ invoice.address.streetAddress }} <br> </span>
                        {{ invoice.address.city }}, {{ invoice.address.state }}<br>
                        {{ invoice.address.country }}
                        {{ invoice.address.postalCode }}
                    </p>
                    <p class="text-secondary">Phone: {{ invoice.customer.phoneNumber }}<br>
                        Email: {{ invoice.customer.email }}
                    </p>
                </div>
            </div>
            <br>
            <div class="row justify-content-md-between">
                <div class="col-md-4 mt-auto">
                    <h6 class="mb-0 text-start text-secondary">
                        Booking no
                    </h6>
                    <h5 class="text-start mb-0">
                        #{{ invoice.bookingNumber }}
                    </h5>
                </div>
                <div class="col-lg-5 col-md-7 mt-auto">
                    <div class="row mt-md-5 mt-4 text-md-end text-start">
                        <div class="col-md-6">
                            <h6 class="text-secondary mb-0">booked date:</h6>
                        </div>
                        <div class="col-md-6">
                            <h6 class="text-dark mb-0">
                                <data-time :date="invoice.bookedDate" :fromNow="false">
                                </data-time>
                            </h6>
                        </div>
                    </div>
                    <div class="row text-md-end text-start">
                        <div class="col-md-6">
                            <h6 class="text-secondary mb-0">On Date:</h6>
                        </div>
                        <div class="col-md-6">
                            <h6 class="text-dark mb-0">
                                <data-time :date="invoice.startDate" :fromNow="false">
                                </data-time></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-6 mb-2">
                    <a class="h6 text-info"><span class="h6 pe-2">Service Name: </span>{{ invoice.serviceName }}</a>
                </div>
                <div class="col-6 mb-2">
                    <a class="h6 text-danger"><span class="h6 pe-2">Booking Status: </span><span class="badge" :class="getStatusClass(invoice.status)">{{ getStatus(invoice.status) }}</span></a>
                </div>
                <div class="col-6 mb-2">
                    <a class="h6 text-info"><span class="h6 pe-2">Plan: </span>{{ invoice.planName || 'NILL' }}</a>
                </div>
                <div class="col-6 mb-2">
                    <a class="h6 text-danger"><span class="h6 pe-2">Payment Status: </span><span class="badge" :class="getPaymentStatusClass(invoice.lastPaymentStatus)">{{ getStatus(invoice.lastPaymentStatus) }}</span></a>
                </div>
                <div class="col-6 mb-2">
                    <a class="h6 text-info"><span class="h6 pe-2">Fequency: </span>{{ invoice.frequencyName || 'NILL' }}</a>
                </div>
                <div class="col-6 mb-5">
                   
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive">
                        <table class="table text-right">
                            <thead class="bg-default">
                                <tr>
                                    <th scope="col" class="pe-2 text-start ps-2">Item</th>
                                    <th scope="col" class="pe-2">Type</th>
                                    <th scope="col" class="pe-2">Qty</th>
                                    <th DataTime scope="col" class="pe-2" colspan="2">Rate</th>
                                    <th scope="col" class="pe-2">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="line in invoice.lines" :key="line.bookingLineId">
                                    <td class="text-start">{{ line.name }}</td>
                                    <td class="text-start">{{ line.type == 1 ? 'Option' : 'Extra' }}</td>
                                    <td class="ps-4"> {{line.quantity }}</td>
                                    <td class="ps-4" colspan="2">£ {{ line.pricePerService }}</td>
                                    <td class="ps-4">£ {{ line.pricePerService * line.quantity }}</td>
                                </tr>
                            </tbody>
                            <tfoot class="mt-4">
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th class="ps-4" colspan="2">Service Charge</th>
                                    <th colspan="1" class="text-right  ps-4">£ {{ invoice.serviceCharge }} </th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th class="ps-4" colspan="2">Subtotal</th>
                                    <th colspan="1" class="text-right  ps-4">£ {{ invoice.subtotalPerService }}</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th class="h5 ps-4 text-danger" colspan="2">Total</th>
                                    <th colspan="1" class="text-right h5 ps-4 text-danger">£ {{ invoice.serviceCharge+invoice.subtotalPerService }}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-lg-12 text-md-end mt-md-0">
                    <button class="btn bg-gradient-info mb-0" @click="printdiv()" type="button"
                        name="button">Print</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BookingService from "../../services/BookingService";
import DataTime from "../../components/common/DataTime.vue";
export default {
    name: "invoice",
    components:[DataTime],
    data(){
        return{
            invoice:{},
            statuses:[{number:0,name:'Pending'},{number:1,name:'New'},{number:2,name:'Accepted'},{number:3,name:'Completed'},{number:4,name:'Cancelled'}],
        }
    },
    async created() {
        console.log("binvoice page");
        let suffix = this.$route.params.bookingNumber;
        console.log(suffix);
        let bookingNumber = suffix.replace("/", "");
        console.log(bookingNumber);
        let result = await BookingService.getByBookingNumber(bookingNumber);
        if(result.success) {
            this.invoice = result.data;
            console.log(this.invoice);
        } else {
            console.log("invoice", "not uccess");
            if(result.message.toLowerCase().includes("not found")){
                this.$router.push({ name: "Error 404" });
            } else {
                this.$toast.error(result.message, {
                position: 'top-right',
                });
            }
        }
        
    },
    methods: {
        getStatus(number){
            var enumName = '';
            this.statuses.filter((element) => {
                if(element.number == number){
                    enumName = element.name;                
                }
            })
            return enumName;
        },
        getPaymentStatus(number){
            var enumName = '';
            let statuses = [{number:1,name:'Pending'},{number:2,name:'Paid'},{number:3,name:'Failed'},{number:4,name:'Cancelled'}]
            statuses.filter((element) => {
                if(element.number == number){
                    enumName = element.name;                
                }
            })
            return enumName;
        },
        getPaymentStatusClass(status){
          if(status === 1){
            return 'bg-gradient-warning';
          }
          if(status === 2){
            return 'bg-gradient-success';
          }
          if(status === 3){
            return 'bg-gradient-danger';
          }
          if(status === 4){
            return 'bg-gradient-secondary';
          }
        },
        getStatusClass(status){
          if(status === 0){
            return 'bg-gradient-warning';
          }
          if(status === 1){
            return 'bg-gradient-info';
          }
          if(status === 2){
            return 'bg-gradient-primary';
          }
          if(status === 3){
            return 'bg-gradient-success';
          }
          if(status === 4){
            return 'bg-gradient-secondary';
          }
        },
        printdiv() {
            var headstr = "<html><head><title>Invoice</title></head><body>";
            var footstr = "</body>";
            var newstr = document.getElementById('invoiceContent').innerHTML;
            var oldstr = document.body.innerHTML;
            document.body.innerHTML = headstr+newstr+footstr;
            window.print();
            document.body.innerHTML = oldstr;
            return false;
        }
    },
    
}
</script>
