<template>
    <div class="py-4 container-fluid" >
        <div class="row">
           <div class="col-12">
              <div class="card mb-4">
                 <div class="card-header pb-0">
                    <div class="d-flex flex-row mb-0 align-items-center">
                        <div class="p-2">
                            <h6>{{ tableTitle }}</h6>
                        </div>
                    </div>
                 </div>
                 <div v-if="isLoading" class="d-flex justify-content-center p-5">
                    <div class="spinner-border text-info" role="status">
                    </div>
                 </div>
                 <div class="card-body px-0 pt-0 pb-2 mx-2" v-else>
                    <d-table :tableStripped="true">
                          <template v-slot:head>
                            <d-table-h v-for='(heading,index) in tableHeadings' :key='index'>
                             {{ heading.name }}
                            </d-table-h>
                          </template>
                           <template v-slot:body>
                            <d-table-row :number="index+1" v-for='(item,index) in tableData' :key='index'>
                              <template v-slot:columns>
                                <d-table-column @column-clicked="null">
                                     <h6 >{{ item.name }}</h6> 
                                </d-table-column>
                                <d-table-column @column-clicked="null">
                                      <h6 >{{ item.subject }}</h6>
                                </d-table-column>
                                <d-table-column>
                                   <div data-bs-toggle="popover" data-bs-trigger="focus hover" :title="item.message" data-bs-content="">
                                      <h6 >{{ item.message }}</h6>
                                    </div>
                                </d-table-column>
                                <d-table-column @column-clicked="null">
                                    <h6 >{{ item.email }}</h6> 
                                </d-table-column>
                                <d-table-column @column-clicked="null">
                                  <data-time :date="item.sentTime">
                                  </data-time>
                                </d-table-column>
                              </template>
                            </d-table-row>
                          </template>
                          <template v-slot:pagination>
                            <d-table-pagination :pageSize="pageSize" :totalPages="totalPages" :selectedPage="selectedPage" @change-page="changePage"></d-table-pagination>
                          </template>
                       </d-table>
                 </div>
              </div>
           </div>
        </div>
     </div>
</template>

<script>
import FeedbackService from "../../services/FeedbackService";
import DataTime from "../../components/common/DataTime.vue";
export default {
    name: "feedbacks",
    mounted() {
        console.log(this.count); // 0
    },
    data() {
        return {
            tableTitle: "Feedbacks",
            tableHeadings: [{ name: "Name" }, { name: "Subject" }, { name: "Message" }, { name: "Email" }, { name: "Time" }],
            tableColumnProps: [
                { name: "feedbackId", type: "number" },
                { name: "name", type: "string" },
                { name: "sentTime", type: "date" },
                { name: "message", type: "string" },
                { name: "subject", type: "string" },
                { name: "email", type: "string" },
            ],
            identityColumn: "feedbackId",
            tableVisibleColumns: ["name", "subject", "message", "email", "sentTime"],
            tableData: [],
            pageSize: 10,
            selectedPage: 1,
            pageNumber: 1,
            totalPages: 1,
            totalCount: 1,
            isLoading: true,
        };
    },
    async created() {
        var response = await FeedbackService.getPagination({ pageNumber: this.pageNumber, pageSize: this.pageSize });
        this.tableData = response.items;
        this.pageNumber = response.pageNumber;
        this.totalPages = response.totalPages;
        this.totalCount = response.totalCount;
        this.isLoading = false;
        // this.$store.dispatch("breadcrumb/reset");
    },
    methods: {
        editRow(identityValue) {
            console.log("edit" + identityValue);
        },
        deleteRow(identityValue) {
            this.frequencies = this.frequencies.filter(item => item.frequencyId != identityValue);
            console.log("delete" + identityValue);
        },
        viewRow(identityValue) {
            console.log("view" + identityValue);
        },
        async changePage(pageNumber) {
            this.selectedPage = pageNumber;
            this.isLoading = true;
            var response = await FeedbackService.getPagination({ pageNumber: pageNumber, pageSize: this.pageSize });
            this.tableData = response.items;
            this.pageNumber = response.pageNumber;
            this.totalPages = response.totalPages;
            this.totalCount = response.totalCount;
            this.isLoading = false;
        },
        showPopover(index){
            console.log('showPopover');
        }
    },
    components: { DataTime }
};
</script>