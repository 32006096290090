<template>
    <tr :class="{'thead-dark': number % 2 == 0}" @mouseover="hovered" @mouseleave="onMouseLeave">
        <td class="align-middle ps-4">
            <h6 class="mb-0 text-sm"> {{ number }} </h6>
        </td>
        <td v-for="(value,index) in data" :key="index" class="align-middle ps-4" v-show="visibleColumns.includes(index)">
            <h6 v-if="getPropType(index,'date')">{{ getDate(value) }}</h6>
            <h6 v-else>{{ getValue(index,value) }}</h6>
            <!-- <h6>{{ getValue(index,value) }}</h6> -->
        </td>
        <slot name="columns">
        </slot>
        <td v-show="showViewButton"  class="align-middle ps-4"><a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="View row" @click="$emit('view-row', this.identityValue)">View</a></td>
        <td v-show="showEditButton"  class="align-middle ps-4"><a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit row" @click="$emit('edit-row', this.identityValue)">Edit</a></td>
        <td v-show="showDeleteButton"  class="align-middle ps-4"><a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Delete row" @click="$emit('delete-row', this.identityValue)">Delete</a></td>
        <slot name="buttons">
        </slot>
    </tr>
</template>
<script>
import moment from 'moment'
export default {
    
    name: "dashboard-table-row",
    inject: ['visibleColumns','showEditButton','showDeleteButton','showViewButton','tableColumnProps'],
    created() {
        //console.log(this.hideColumns);
    },
    props: {
        tableHeading: String,
        data: Object,
        rowKey: Number,
        number: Number,
        identityValue: String,
    },
    data() {
        return{
            isHovered: false,
        }
    },
    emits: ["edit-row","delete-row","view-row","table-row-hovered"],
    methods: {
        hovered(){
            this.isHovered = true;
            this.$emit("table-row-hovered", this.rowKey);
        },
        onMouseLeave(){
            this.isHovered = true;
        },
        edit(){
            this.$emit("edit-row", this.identityValue);
        },
        onDelete(){
            this.$emit("delete-row", this.identityValue);
        },
        view(){
            this.$emit("view-row", this.identityValue);
        },
        getPropType(name, type){
            var state = false;
            this.tableColumnProps.filter((element) => {
                if(element.name == name){
                    if(element.type === type){
                        state = true;
                    } else {
                        state = false;
                    }                    
                }
            })
            return state;
        },
        getEnumValue(enumData, number){
            
            var enumName = '';
            enumData.filter((element) => {
                if(element.number == number){
                    enumName = element.name;                
                }
            })
            return enumName;
        },
        getValue(name, value){
            var result = '';
            this.tableColumnProps.filter((element) => {
                if(element.name == name){
                    if(element.type === 'enum'){
                        result = this.getEnumValue(element.enumData, value);
                    } else {
                        result = value;
                    }                    
                }
            })
            return result;
        },
        getDate(date){
            return moment.utc(date).fromNow();
        }
    }
}
</script>