<template>
    <div class="card mb-4">
         <div class="card-header pb-0">
            <div class="d-flex flex-row mb-0 align-items-center">
               <div class="p-2"> {{ title }}:</div>
               <div class="p-2">
               </div>
               <div class="p-2 ms-auto">
                  <button type="button" class="btn btn-outline-success rounded-pill btn-sm" data-bs-toggle="modal" data-bs-target="#modal-form">Edit</button>
               </div>
            </div>
         </div>
         <hr class="mt-3 mb-0 mt-0"/>
         <div class="card-body px-0 pt-0 pb-2">
            <div class="m-3">
               <slot name="info-contents">
               </slot>
            </div>
            <div class="m-3 d-flex flex-row-reverse" v-show="showDeleteButton">
               <button-circle icon="fa fa-trash" :showButton="true" index="btn-dlt" :isDropdownButton="false" classes="btn-outline-danger"  @button-clicked="deleteThis"/>
            </div>
         </div>
      </div>
</template>
<script>
export default {
    //inject: ['showEditButton'],
    name:"info-card",
    props:{
       title: String,
       id: String,
       showDeleteButton: Boolean,
    },
    methods: {
       deleteThis(){
          this.$emit('delete-data');
       }
    }
}
</script>