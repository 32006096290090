<template>
                        <table class="table align-items-center mb-0 " :class="{'table-striped ': tableStripped}">
                          <thead>
                             <tr>
                                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">No.</th>
                                <th v-for='(heading,index) in columnHeadings'
                                    :key='index'  
                                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> {{ heading.name }} </th>
                                <th class="text-secondary opacity-7"></th>
                             </tr>
                          </thead>
                          <tbody>
                              <dashboard-table-row v-for="(item, key) in data" :key="key"
                              :number="key+1"
                              :data="item"
                              :rowKey="key"
                              :identityValue="item[identityPropertyName].toString()"
                              @edit-row="editRow"
                              @delete-row="deleteRow"
                              @view-row="viewRow">
                                <template v-slot:columns>
                                  <slot name="columns"></slot>
                                </template>
                                <template v-slot:buttons>
                                  <slot name="buttons"></slot>
                                </template>
                              </dashboard-table-row>
                          </tbody>
                       </table>
                        <div v-if="hasPagination">
                            <!-- <ul class="pagination pagination-info d-flex justify-content-end mt-3">
                                <li class="page-item">
                                <a class="page-link" aria-label="Next" v-if="hasPreviousPage && selectedPage > 3" @click="firstPage">
                                    <span aria-hidden="true"><i class="fa fa-angle-double-left" aria-hidden="true"></i></span>
                                </a>
                                </li>
                                <li class="page-item">
                                <a class="page-link" aria-label="Previous" v-if="hasPreviousPage" @click="previousPage">
                                    <span aria-hidden="true"><i class="ni ni-bold-left" aria-hidden="true"></i></span>
                                </a>
                                </li>
                                <li class="page-item" v-for='page in getPages' :key='page' :class="{ 'active' : selectedPage === page}">
                                <a class="page-link" @click="onChangePage(page)"> {{ page }}</a>
                                </li>
                                <li class="page-item">
                                <a class="page-link" aria-label="Next" v-if="hasNextPage" @click="nextPage">
                                    <span aria-hidden="true"><i class="ni ni-bold-right" aria-hidden="true"></i></span>
                                </a>
                                </li>
                                <li class="page-item">
                                <a class="page-link" aria-label="Next" v-if="hasNextPage" @click="lastPage">
                                    <span aria-hidden="true"><i class="fa fa-angle-double-right" aria-hidden="true"></i></span>
                                </a>
                                </li>
                            </ul> -->
                            <vsud-pagination color="success" class="d-flex justify-content-end mt-3">
                                <vsud-pagination-item first @click="firstPage" v-if="hasPreviousPage && selectedPage > 3"/>
                                <vsud-pagination-item prev v-if="hasPreviousPage" @click="previousPage"/>
                                <vsud-pagination-item :label="page" :active="selectedPage === page"  v-for='page in getPages' :key='page'  @click="onChangePage(page)"/>
                                <vsud-pagination-item next  v-if="hasNextPage" @click="nextPage"/>
                                <vsud-pagination-item last  v-if="hasNextPage" @click="lastPage"/>
                            </vsud-pagination>
                        </div>
</template>
<script>
import DashboardTableRow from './DataTableRow.vue';
import VsudPagination from "@/components/VsudPagination.vue";
import VsudPaginationItem from "@/components/VsudPaginationItem.vue";
export default {
    name: "dashboard-table",
    components: { 
        DashboardTableRow,
        VsudPagination,
        VsudPaginationItem
        },
    props: {
        tableStripped: Boolean,
        data: Array,
        columnHeadings: Array,
        identityPropertyName: String,
        showEditButton: Boolean,
        showDeleteButton: Boolean,
        showActionButton: Boolean,
        visibleColumns: Array,
        tableColumnProps: Array,
        hasPagination:Boolean,
        selectedPage:{
            type: Number,
            required: false,
            default: 1,
        },
        totalPages:{
            type: Number,
            required: false,
            default: 10,
        },
        pageSize: {
            type: Number,
            required: false,
            default: 10,
        }
    },
    created(){
        
    },
    data() {
        return {
            hoveredRow: null,
        }
    },
    computed: {
        getPages(){
            if(this.totalPages > 5){
                var startFrom =  this.selectedPage - 2;
                startFrom = startFrom < 1 ? 1 : startFrom;
                startFrom = (startFrom + 5) > this.totalPages ? this.totalPages -4 : startFrom;
                var pages = Array.from({length: 5}, (_, i) => i + startFrom);
                // if(pages.includes(this.totalPages)){
                //     startFrom = this.totalPages - 4;
                //     pages = Array.from({length: 5}, (_, i) => i + startFrom);
                // }
                return pages;
            } else {
                return Array.from({length: this.totalPages}, (_, i) => i + 1)
            }
        },
      hasNextPage: function () {
        return this.totalPages < 5 ? false : this.selectedPage == this.totalPages ? false : !this.getPages.includes(this.totalPages);
      },
      hasPreviousPage: function () {
        return this.selectedPage < 2 ? false : this.totalPages < 5 ? false : true
      },
    },
    provide() {
        return {
            visibleColumns: this.visibleColumns,
            showEditButton: this.showEditButton,
            showDeleteButton: this.showDeleteButton,
            showViewButton: this.showActionButton,
            tableColumnProps: this.tableColumnProps
        }
    },
    emits: ["edit-row","delete-row","view-row","change-page"],
    methods: {
        hovereRow(key) {
            this.hoveredRow =  key;
            console.log(this.hoveredRow);
        },
        editRow(identityValue) {
             this.$emit("edit-row", identityValue);
        },
        deleteRow(identityValue) {
             this.$emit("delete-row", identityValue);
        },
        viewRow(identityValue) {
             this.$emit("view-row", identityValue);
        },
        onChangePage(index) {
            //var pageNumber = this.getPageNumber(index);
            if(index !== this.selectedPage) {
                this.$emit("change-page", index);
            }
        },
        getPageNumber(index){
            return index + this.selectedPage;
        },
        nextPage(){
            // var removedPageNumber = this.pageNumbers.shift();
            // var newNumber = removedPageNumber+5;
            // if(newNumber <= this.totalPages) {
            //     this.pageNumbers.push(newNumber);
            // } else {
            //     this.pageNumbers.unshift(removedPageNumber);
            // }
            
            this.onChangePage(this.selectedPage+1)
        },
        previousPage(){
            // var removedPageNumber = this.pageNumbers.pop();
            // var newNumber = removedPageNumber-5;
            // if(newNumber > 0) {
            //     this.pageNumbers.unshift(newNumber);
            // } else {
            //     this.pageNumbers.push(removedPageNumber);
            // }
            this.onChangePage(this.selectedPage-1)
        },
        firstPage(){
            this.onChangePage(1)
        },
        lastPage(){
            this.onChangePage(this.totalPages)
        },
    },
}
</script>