<template>
  <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
    <slot></slot>
  </th>
</template>

<script>
export default {
    name: 'd-th',
}
</script>

<style>

</style>