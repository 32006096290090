import http from "../common/http-config";

const apiPrefix = `Emails/`;


class EmailService {
    async getAll() {
        return await http.get(apiPrefix).then((response) => {
          if (response.data) {
            return response.data.data;
          } else {
            return [];
          }
        });
    }
    async sendPersonalEmail(data) {
      //let category = {name: name, description: description, visible: visible}
      return await http.post(apiPrefix+'PersonalEmail/Send', data).then((response) => {
        return response.data;
      });
    }
}
export default new EmailService()