import AuthService from "../services/AuthService";

const tokenData = localStorage.getItem("token");
//const refreshToken = localStorage.getItem("refreshToken");
const user = JSON.parse(localStorage.getItem("user"));
const token =
  tokenData === null ? null : localStorage.getItem("token");
const initialState = token
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          // console.log("Error login failure: ")
          // console.log(error)
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    refreshToken({ commit }) {
      let token = this.state.status.user.refresToken;
      return AuthService.refreshToken(token).then(
        (user) => {
          commit("refreshTokenSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("refreshTokenFailue");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },    
    refreshTokenSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    refreshTokenFailue(state, user) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
};
