<template>
<button type="button" class="btn btn-circle mb-0 ml-5 me-2"
   aria-expanded="false" :class="getButtonColor" v-show="showButton" v-if="!isDropdownButton"  @click="$emit('button-clicked', this.index)"><i :class="icon"></i></button>
<button type="button" class="btn btn-circle mb-0 ml-5 me-2" :id="btnId" data-bs-toggle="dropdown" 
   aria-expanded="false" :class="getButtonColor" v-show="showButton" v-if="isDropdownButton" @click="$emit('button-clicked', this.index)"><i :class="icon"></i></button>
</template>

<script>

export default {
   name: "button-circle",
   props: {
      isDropdownButton: {
         type: Boolean,
         required: false,
      },
      showButton: Boolean,
      icon: String,
      classes: {
         type: String,
         required: false,
      },
      index: {
         type: String,
         required: true,
      },
      btnId: {
         type: String,
         required: false,
      },
    // to: {
    //     type: [Object, String],
    //     required: true,
    // },
   },
   // setup (props, { emit }) {
   //        const buttonClicked = (event) => {
   //            emit("button-clicked", props.index)
   //        }
   //        return {
   //            buttonClicked
   //        }
   //    },
   emits: ["button-clicked"],
   methods:{
      getButtonColor() {
         return this.classes || 'btn-outline-success';
      },
      makeid(length) {
         var result = '';
         var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
         var charactersLength = characters.length;
         for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
         }
         return result;
      },
   }
};
</script>
