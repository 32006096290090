<template>
  <li class="page-item" :class="getClasses(active, disabled)">
    <a class="page-link" href="javascript:;">
      {{ prev || next || first || last ? null : label }}
      <i v-if="prev" class="fa fa-angle-left"></i>
      <i v-if="next" class="fa fa-angle-right"></i>
      <i v-if="first" class="fa fa-angle-double-left"></i>
      <i v-if="last" class="fa fa-angle-double-right"></i>
    </a>
  </li>
</template>

<script>
export default {
  name: "vsud-pagination-item",
  props: {
    label: Number,
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    prev: {
      type: Boolean,
      default: false,
    },
    next: {
      type: Boolean,
      default: false,
    },
    first: {
      type: Boolean,
      default: false,
    },
    last: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getClasses: (active, disabled) => {
      let activeValue, disabledValue;

      activeValue = active ? "active" : null;
      disabledValue = disabled ? "disabled" : null;

      return `${activeValue} ${disabledValue}`;
    },
  },
};
</script>
