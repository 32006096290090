<template>
    <div class="py-4 container-fluid" >
        <div class="row">
           <div class="col-12">
              <div class="card mb-4">
                 <div class="card-header pb-0">
                    <h6>{{ tableTitle }}</h6>
                 </div>
                 <div class="card-body px-0 pt-0 pb-2 mx-2">
                    <div class="table-responsive p-0">
                       <dashboard-table 
                       :tableStripped="true"
                       :columnHeadings="tableHeadings"
                       :data="tableData"
                       identityPropertyName="brandId"
                       :hideColumns="['brandId']"
                       :showActionButton="false"
                       :showEditButton="true"
                       :showDeleteButton="true"
                       @edit-row="editRow"
                              @delete-row="deleteRow"
                              @view-row="viewRow">>
                       </dashboard-table>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
</template>

<script>
import DashboardTable from '../../components/DataTable.vue';

export default {
  name: "brands",
  components: {
    DashboardTable,
  },
  data(){
    return{
      tableTitle: 'Brands',
      tableHeadings: [{ name:'Name'},{ name:'Code'},{ name:'Visibility'}],
      tableDataProps: ['name','code','visible'],
      tableData: [{brandId: 1, name:'Nike',code:'B001',visible:true},{brandId: 2, name:'Adidas',code:'B002',visible:true},{brandId: 3, name:'Puma',code:'B003',visible:false}]
    }
  },
  created() {
    this.$store.dispatch("breadcrumb/reset");
  },
  methods:{
    editRow(identityValue) {
      console.log("edit"+ identityValue);
    },
    deleteRow(identityValue) {
      this.tableData = this.tableData.filter(brand => brand.brandId != identityValue);
      console.log("delete"+ identityValue);
    },
    viewRow(identityValue) {
      console.log("view"+ identityValue);
    },
  },
};
</script>