import axios from 'axios'
import store from '../store'
import router from '../router'
import Base_API_URL from "../constants/base.js";

//const Url = 'https://192.168.8.100:45458/api/'
//const Url = 'https://mytaxis.azurewebsites.net/api/'
//const Url = 'https://mytaxis.myweb.net.au/api/'
const Url = Base_API_URL.API_URL
axios.defaults.baseURL = Url;
const instance = axios.create({
    //baseURL: Url,
    headers: {
        'Content-type': 'application/json',
    },
})

// instance.interceptors.request.use( async config => 
//     { config.baseURL= await getBaseUrl(); return config; }, error => Promise.reject(error) );


instance.interceptors.request.use(function (config) {
    // console.log(config.baseURL);
    // console.log(config.url);
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
    // config.header('Access-Control-Allow-Origin', '*');
    // config.header('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    return config
}, function (error) {
    //console.log('config error: ');
    //console.log(error);
    return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {
    //console.log('response: ');
    //console.log(response);
    return response
}, function (error) {
    //console.log('error: ');
    //console.log(error);
    //console.log(error.response.status);
    if (typeof(error.response) !== "undefined") {
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
        if (error.response.status === 401) {
            if (error.response.data.message === 'Invalid token') {
                store.dispatch('auth/logout')
                    .then(() => router.push('/sign-in'))
            } else {
                // store.dispatch('auth/logout').then(() => {
                //     store.dispatch('auth/refreshToken').then(() => {
                //         error.config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
                //         return instance.request(error.config)
                //     })
                // })
                store.dispatch('auth/logout')
                    .then(() => router.push('/sign-in'))
            }
        }
        
    } else {
        if(error.message === 'Request aborted') {
            console.log(error.message);
        }
    }
    
    return Promise.reject(error)
})

export default instance