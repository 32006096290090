<template>
   <div class="table-responsive p-0">
      <div class="d-flex flex-column mb-3">
         <div v-for='(item, key) in list' :key='key'>
            <card-list-item 
            :showDivider="true" 
            :name="item.name" 
            :noOfSubItems="getPropertyValue(subListCountPropName, item)" 
            :subListName="subListName"
            :hasSubList="hasSubList"
            :itemIndex="key"> 
               <template v-slot:right-buttons="slotProps">
                  <slot name="list-right-buttons" v-bind:hovered="slotProps.hovered" v-bind:itemIndex="slotProps.itemIndex">
                  </slot>
               </template>
            </card-list-item>
            <hr class="bmt-3 my-0 mx-3">
         </div>
      </div>
   </div>
</template>

<script>
import CardListItem from './CardListItem.vue';
   
   export default {
      name: "card-list",
      components:{
         CardListItem,
      },
      props:{
         list: Array,
         subListName: String,
         subListCountPropName: String,
         upHere: Boolean,
         hasSubList : Boolean,
      },
      methods: {
         getPropertyValue (propName, targetObject) {
            var hasProperty = Object.prototype.hasOwnProperty.call(targetObject, propName);
              if (hasProperty) {
                return targetObject[propName];
              }
            return 0;
         }
      }
   };
</script>