<template>
    <div class="py-4 container-fluid" >
        <div class="row">
           <div class="col-12">
              <div class="card mb-4">
                 <div class="card-header pb-0">
                    <div class="d-flex flex-row mb-0 align-items-center">
                        <div class="p-2">
                            <h6>{{ tableTitle }}</h6>
                        </div>
                        <div class="p-2 ms-auto">
                            <button type="button" class="btn btn-outline-success rounded-pill btn-sm" data-bs-toggle="modal" data-bs-target="#add-frequency">Add</button>
                        </div>
                    </div>
                 </div>
                 <div v-if="isLoading" class="d-flex justify-content-center p-5">
                    <div class="spinner-border text-info" role="status">
                    </div>
                 </div>
                 <div class="card-body px-0 pt-0 pb-2 mx-2" v-show="!isLoading">
                    <div class="table-responsive p-0">
                       <dashboard-table 
                       :tableStripped="true"
                       :columnHeadings="tableHeadings"
                       :data="frequencies"
                       identityPropertyName="frequencyId"
                       :visibleColumns="tableVisibleColumns"
                       :showActionButton="false"
                       :showEditButton="false"
                       :showDeleteButton="false"
                       :tableColumnProps="tableColumnProps"
                       :hasPagination="false"
                       @edit-row="editRow"
                       @delete-row="deleteRow"
                       @view-row="viewRow">
                       <template v-slot:buttons>
                         <!-- <td class="align-middle ps-4" :id="identityValue"><a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="Edit user">Delete</a></td> -->
                       </template>
                       </dashboard-table>
                    </div>
                    <button-large class="mt-5" icon="fa fa-plus" data-bs-toggle="modal" data-bs-target="#add-category"/>
                 </div>
              </div>
           </div>
        </div>
     </div>
     <add-model idName="add-frequency" description="Please provide category information" title="Add Frequency">
      <template v-slot:body-content>
        <form role="form text-left">
                      <label>Name</label>
                      <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Name" aria-label="Name" aria-describedby="name-addon" v-model="addFrequencyModel.name">
                      </div>
                      <label>Desctiption</label>
                      <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Code" aria-label="Code" aria-describedby="code-addon" v-model="addFrequencyModel.code">
                      </div>
                      <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="visible" :checked="addFrequencyModel.visible">
                        <label class="form-check-label" for="visible">Visible</label>
                      </div>
                    </form>
      </template>
      <template v-slot:body-footer>
        <button type="button" class="btn bg-gradient-primary" @click="AddCategory">Add</button>
      </template>
    </add-model>
</template>

<script>
import DashboardTable from '../../components/DataTable.vue';
import FrequenyService from "../../services/FrequenyService";

export default {
  name: "frequencies",
  setup(){
    const isLoading = true;
    return{
        isLoading
    } 
  },
  components: {
    DashboardTable,
  },
  mounted() {
    console.log(this.count) // 0
  },
  data(){
    return{
      tableTitle: 'Frequencies',
      tableHeadings: [{ name:'Name'},{ name:'Code'},{ name:'Type'}, { name:'Interval Count'}],
      tableColumnProps: [
        {name:'frequencyId', type:'number'},
        {name:'frequencyName', type:'string'},
        {name:'frequencyCode', type:'string'},
        {name:'intervalCount', type:'number'},
        {name:'type', type:'enum',  enumData: [
          {number:1,name:'Day'},{number:2,name:'Week'},{number:3,name:'Month'},{number:4,name:'Year'},
        ]}
      ],
      tableVisibleColumns:['frequencyName','frequencyCode','intervalCount','type'],
      frequencies: [],
      addFrequencyModel:{ name: '', code: '', visible: false, locationId: 0},
    }
  },
  async created() {
      this.frequencies = await FrequenyService.getAll();
      this.isLoading = false;
   // this.$store.dispatch("breadcrumb/reset");
  },
  methods:{
    editRow(identityValue) {
      console.log("edit"+ identityValue);
    },
    deleteRow(identityValue) {
      this.frequencies = this.frequencies.filter(item => item.frequencyId != identityValue);
      console.log("delete"+ identityValue);
    },
    viewRow(identityValue) {
      console.log("view"+ identityValue);
    },
  },
};
</script>