<template>
  <div class="row">
    <div class="col col-lg-8">
      <div class="card mb-4">
         <div class="card-header pb-0">
            <div class="d-flex flex-row mb-0 align-items-center">
               <div class="p-2">Selected Service: </div>
               <div class="p-2">
                  <div class="dropdown">
                     <button class="btn bg-white dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                      {{ selectedService.name }}
                     </button>
                     <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li v-for='(service,index) in Services' :key='index' 
                        v-show="service.serviceId != selectedService.serviceId" 
                        @click="serviceChanged(service)">
                          <a class="dropdown-item" href="javascript:;">{{ service.name }}</a>
                        </li>
                        <!-- <li><a class="dropdown-item" href="javascript:;">service 2</a></li> -->
                     </ul>
                  </div>
               </div>
               <div class="p-2 ms-auto">
                  <button type="button" class="btn btn-outline-success rounded-pill btn-sm" data-bs-toggle="modal" data-bs-target="#add-category">Add</button>
               </div>
            </div>
         </div>
         <hr class="mt-3 mb-0 mt-0"/>
         <div class="card-body px-0 pt-0 pb-2"> 
            <card-list :list="getSelectedCategories()" :subListName="subListName" :subListCountPropName="subListCountPropName" :hasSubList="true">
            <template v-slot:list-right-buttons="slotProps">
                <button-circle icon="fa fa-ellipsis-h" :showButton="slotProps.hovered" btnId="btn-001" :isDropdownButton="true" :index="slotProps.itemIndex.toString()" />
                <ul class="dropdown-menu" aria-labelledby="btn-001">
                  <li><a class="dropdown-item" href="javascript:;">Action</a></li>
                  <li><a class="dropdown-item" href="javascript:;">Another action</a></li>
                  <li><a class="dropdown-item" href="javascript:;">Something else here</a></li>
                </ul>
                <button-circle icon="fa fa-clone" :showButton="slotProps.hovered" :isDropdownButton="false" :index="slotProps.itemIndex.toString()"  @button-clicked="onClickView"/>
                <button-circle icon="ni ni-bold-right" :showButton="slotProps.hovered" :isDropdownButton="false" :index="slotProps.itemIndex.toString()" @button-clicked="onClickView"/>
            </template>
            </card-list>
            <button-large icon="fa fa-plus" data-bs-toggle="modal" data-bs-target="#add-category"/>
         </div>
      </div>
    </div>
    <div class="col col-lg-4" v-show="false">
      <info-card title="service Info" :showDeleteButton="false">
        <template v-slot:info-contents>
          <h6>{{ selectedService.name }}</h6>
          <a>{{ selectedService.description }}</a>
          <label>Visibility</label><br>
          <a v-show="selectedService.visible"><i class="fa fa-eye" aria-hidden="true"></i>  Show to customer</a>
          <a v-show="!selectedService.visible"><i class="fa fa-eye-slash" aria-hidden="true"></i>  Hide to customer</a>
        </template>
      </info-card>
    </div>
  </div>
<add-model idName="add-category" description="Please provide category information" title="Add Category">
  <template v-slot:body-content>
    <form role="form text-left">
                  <label>Name</label>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Category name" aria-label="Name" aria-describedby="name-addon" v-model="addCategoryModel.name">
                  </div>
                  <label>Desctiption</label>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Description" aria-label="Description" aria-describedby="description-addon" v-model="addCategoryModel.description">
                  </div>
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="visible" :checked="addCategoryModel.visible">
                    <label class="form-check-label" for="visible">Visible</label>
                  </div>
                </form>
  </template>
  <template v-slot:body-footer>
    <button type="button" class="btn bg-gradient-primary" @click="AddCategory">Add</button>
  </template>
</add-model>
</template>

<script>
//import { CategoryList } from '@/data/categories.js'
import ServiceService from "../../services/ServiceService";
import CategoryService from "../../services/CategoryService";
import bootstrap from "bootstrap/dist/js/bootstrap";
import InfoCard from '../../components/InfoCard.vue';

export default {
  components: { InfoCard },
  name: "Service",
  emits: ["click"],
  data() {
    return {
      listTitle: 'Categories',
      subListRootName: 'Category',
      subListTitle: 'Products in Categories',
      subListName: 'Products',
      subListCountPropName: 'noOfProducts',
      addCategoryModel:{ name: '', description: '', visible: false, serviceId: 0},
      Services: [],
      selectedService: {},
      addModel: '',
    };
  },
  mounted() {
    this.addModel = new bootstrap.Modal(document.getElementById("add-category"))
  },
  async created(){
    let serviceId = this.$route.params.serviceId;
    // this.$store.dispatch("breadcrumb/reset");
    // this.$store.dispatch("breadcrumb/add", item);
    this.showAddCategoryModel = false;
    this.categories = [];
    let services = await ServiceService.getAll();
    this.Services = services;
    let service = {};
    console.log(services);
    services.forEach(function(item) {
      if(item.serviceId === serviceId){
        service = item;
      }
    });
    if(service) {
      this.selectedService = service;
    }
  },
  methods:{
    addCategoryToList(category){
      this.categories.push(category);
    },
    async AddCategory(){
      this.addCategoryModel.serviceId = this.selectedService.serviceId;
      let result = await CategoryService.add(this.addCategoryModel);
      console.log(result);
      if(result.success) {
        let data = result.data;
        let category = { name: data.name, noOfSubItems: 0, subItemType: this.subListName };
        this.addCategoryToList(category);
        this.addCategoryModel.name = ''; this.addCategoryModel.description = '';
        this.closeModal();
        this.$router.push({ name: this.subListRootName, params: { serviceId: data.serviceId, categoryId: data.categoryId }});
      } else {
        this.$toast.error(result.message, {
          position: 'top-right',
        });
      }
    },
    onClickView(index){
      console.log(index);
      var category = this.getCategory(index);
      console.log(category);
      this.$router.push({ name: this.subListRootName, params: { categoryId: category.categoryId, serviceId: category.serviceId}});
    },
    serviceChanged(service){
      this.selectedService = service;
    },
    getSelectedCategories(){
      return this.selectedService.categories;
    },
    getCategory(index){
      var categories = this.getSelectedCategories();
      return categories[index];
    },
    closeModal() {
      this.addModel.hide();
    },
  },
};
</script>